export interface EnvironmentVariables {
    readonly awsAccount: string;
    readonly identityPoolId: string;
    readonly userPoolClientId: string;
    readonly userPoolId: string;
    readonly identifyPoolDomain: string;
    readonly awsRegion: string;
    readonly stage: string;
    readonly cloudFrontProtocol: string;
    readonly cloudFrontDomain: string;
    readonly AWS_ACCESS_KEY_ID: string;
    readonly AWS_SECRET_ACCESS_KEY: string;
    readonly appsyncEndpoint: string;
}

export const envVariables: EnvironmentVariables = {
    awsAccount: '872149098089',
    identityPoolId: 'us-east-1:43c3bcd1-cad6-426a-a6b3-f110fb2b4b63',
    userPoolClientId: '35piligag5jq8tjpgo486g35qv',
    userPoolId: 'us-east-1_W0MHk2phe',
    identifyPoolDomain: 'side-far-beta.auth.us-east-1.amazoncognito.com',
    awsRegion: 'us-east-1',
    stage: 'beta',
    cloudFrontProtocol: 'https',
    cloudFrontDomain: 'd25xo16tgzhpdc.cloudfront.net',
    AWS_ACCESS_KEY_ID: '{{AWS_ACCESS_KEY_ID}}',
    AWS_SECRET_ACCESS_KEY: '{{AWS_SECRET_ACCESS_KEY}}',
    appsyncEndpoint: 'api.beta.far.gso.amazon.dev',
};

export const MIDWAY = {
    URL: 'https://midway-auth.amazon.com/SSO',
    LOGOUT_URL: 'https://midway-auth.amazon.com/SSO/redirect',
    SCOPE: 'openid',
    RESPONSE_TYPE: 'id_token',
    SENTRY_HANDLER_VERSION: 'MidwayNginxModule-1.3-1',
};

export const ProdDomain = 'far.gso.amazon.dev';

export const URLS = {
    ReportABug:
        'https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&severity=FIVE&category=Global+Security+Ops+-+Systems&type=SIDEFAR&item=General&building_id=AUS18&group=SIDE+Development+Team&assignee=side-support-primary&asin=&quantity=&isd=&upc=&binding=&vendor_id=&purchase_order_id=&stock_number=&ship_origin=&invoice_number=&physical_location=&tracking_number=&bol_number=&picture_file_imdex_location=&vendor_info_title=',
    FeatureRequest: 'https://tiny.amazon.com/1dwqzl1pa/SIDEFAR-FeatureRequest',
    UserGuide: 'https://w.amazon.com/bin/view/SIDE/SIDEFAR/',
    Contact: 'https://w.amazon.com/index.php/SIDE#HContactus',
};
